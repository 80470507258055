<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Left Text-->
      <b-col
        lg="7"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Forgot password V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col
        lg="5"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-2 text-center"
          >
            <b-link class="text-center">
              <img
                :src="logoUrl"
                alt=""
                srcset=""
                height="100"
                weight="80"
              >
            </b-link>
            <br>
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t('forgot.subtitle') }}
          </b-card-text>

          <!-- form -->
          <b-form
            class="auth-forgot-password-form mt-2"
            @submit.prevent="validationForm"
          >
            <b-form-group
              label="Email"
            >
              <b-form-input
                v-model="$v.userEmail.$model"
                :formatter="lower"
                :class="{
                  'is-invalid': $v.userEmail.$error
                }"
                placeholder="example@godwin.com"
              />
              <div
                v-if="$v.userEmail.$error"
                class="invalid-feedback"
              >
                <span
                  v-if="!$v.userEmail.required"
                >{{ $t('errors.required') }}</span>
                <span
                  v-if="!$v.userEmail.email"
                >{{ $t('errors.email') }}</span>
              </div>
            </b-form-group>

            <b-button
              type="submit"
              variant="primary"
              block
              :disabled="loaded || process"
            >
              <b-spinner
                v-if="loaded || process"
                class="mr-1"
                small
              />
              <span v-show="!loaded || process">{{ $t('send') }}</span>
            </b-button>
          </b-form>
          <b-card-text class="text-center mt-2">
            <span>{{ $t('forgot.remember') }} </span>
            <b-link :to="{name:'login'}">
              <feather-icon icon="ChevronLeftIcon" /> <span>&nbsp;{{ $t('login.sign-in') }}</span>
            </b-link>
          </b-card-text>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import store from '@/store/index'
import { mapActions, mapGetters } from 'vuex'
import { required, email } from 'vuelidate/lib/validators'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
  },
  data() {
    return {
      loaded: false,
      userEmail: null,
      sideImg: require('@/assets/images/pages/forgot.svg'),
      logoImg: require('@/assets/images/logo/logo-sombre.svg'),
    }
  },
  validations: {
    userEmail: {
      required,
      email,
    },
  },
  computed: {
    ...mapGetters({
      process: 'resetProcess',
      error: 'resetError',
      success: 'resetSuccess',
    }),
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    logoUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.logoImg = require('@/assets/images/logo/logo-sombre.svg')
        return this.logoImg
      }
      return this.logoImg
    },
  },
  watch: {
    success(val) {
      if (val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('forgot.init'),
            text: this.$t('forgot.success'),
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
        setTimeout(() => {
          this.$router.push({ name: 'login' })
        }, 2000)
      }
    },
    error(val) {
      if (val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('forgot.init'),
            text: this.$t('forgot.error'),
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
  methods: {
    ...mapActions(['initPassReset']),
    async validationForm() {
      this.$v.$touch()
      if (this.$v.$error) return
      this.loaded = true
      const data = JSON.stringify({
        email: this.userEmail,
      })
      await this.$http({
        method: 'post',
        url: '/check-user-by-email',
        data,
      })
        .then(res => {
          this.loaded = false
          if (res.data === 'NOT_EXIST') {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t('forgot.init'),
                text: this.$t('forgot.not-exist'),
                icon: 'XCircleIcon',
                variant: 'warning',
              },
            })
            return
          }
          this.initPassReset({
            email: this.userEmail,
          })
        })
        .catch(() => {
          this.loaded = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('forgot.init'),
              text: this.$t('forgot.error'),
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        })
    },
    lower(value) {
      return value.toLowerCase()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
